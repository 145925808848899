<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Work Position</label>
        <work-position
          @workPosition="setWorkPosition"
          :id="workPosition.ID"
        ></work-position>
      </div>
    </div>
    <div class="vx-row mb-6" v-if="workPosition.level != 1">
      <div class="vx-col w-full">
        <label class="vs-input--label">Person Parent</label>
        <personal-parent
          :id="parentPersonal.id"
          @personal="setParentPersonal"
          :level="data.level"
          :workTeamId="data.workTeamId"
          :workTeamPersonalId="id"
        ></personal-parent>
      </div>
    </div>
    <div class="vx-row mb-6" v-if="workPosition.level">
      <div class="vx-col w-full">
        <label class="vs-input--label">Sales Area</label>
        <territory-area
          @territoryArea="setTerritoryArea"
          :id="territoryArea.id"
          :disable="true"
        ></territory-area>
      </div>
    </div>
    <div
      class="vx-row mb-6"
      v-if="workPosition.level && workPosition.level != 1"
    >
      <div class="vx-col w-full">
        <label class="vs-input--label">Territory</label>
        <territory
          @territory="setTerritory"
          :id="territory.id ? territory.id : territory.ID"
          :territoryAreaId="
            territoryArea.id ? territoryArea.id : territoryArea.ID
          "
          :disable="parentPersonal.id && workPosition.level > 2 ? true : false"
        ></territory>
      </div>
    </div>
    <div class="vx-row mb-6" v-if="workPosition.level">
      <div class="vx-col w-full">
        <label class="vs-input--label">Person</label>
        <div v-for="(personal, index) in personals" :key="index">
          <br v-if="index > 0" />
          <vx-input-group>
            <personal
              :id="personals[index].id"
              @personal="setPersonal"
              :territoryAreaId="territoryArea.id"
              :territoryId="territory.id"
              :workTeamPersonalId="id"
              :level="workPosition.level"
              :index="index"
              :personal="personals"
            ></personal>
            <template slot="append" v-if="workPosition.level == 4 && !id">
              <div class="append-text btn-addon">
                <vs-button
                  v-if="index == 0"
                  color="success"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="handleAddPerson()"
                ></vs-button>
                <vs-button
                  v-else
                  color="danger"
                  icon-pack="feather"
                  icon="icon-minus"
                  @click="handleRemovePerson(index)"
                ></vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" v-if="workPosition.level">
      <div class="vx-col w-full">
        <label class="vs-input--label">Status</label>
        <vs-switch
          color="dark"
          icon-pack="feather"
          vs-icon-on="icon-check-circle"
          vs-icon-off="icon-slash"
          v-model="data.isActive"
        >
          <span slot="on">Active</span>
          <span slot="off">Inactive</span>
        </vs-switch>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import WorkPosition from "./form-component/WorkPosition";
import TerritoryArea from "./form-component/TerritoryArea";
import Territory from "./form-component/Territory";
import Personal from "./form-component/Personal";
import PersonalParent from "./form-component/PersonalParent";
export default {
  components: {
    WorkPosition,
    TerritoryArea,
    Territory,
    Personal,
    PersonalParent,
  },
  props: {
    id: {
      type: Number,
    },
    workTeam: {
      type: Object,
    },
    baseUrl: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          parentId: null,
          workTeamId: null,
          workPositionId: null,
          personalId: null,
          territoryAreaId: null,
          territoryId: null,
          warehouseId: null,
          level: null,
          isActive: true,
        },
        workPosition: {
          id: null,
          name: "",
          level: null,
        },
        personals: [
          {
            id: null,
            name: "",
          },
        ],
        parentPersonal: {
          id: null,
          name: "",
          workTeamPersonal: {
            id: null,
            parentId: null,
            workTeamId: null,
            workPositionId: null,
            personalId: null,
            territoryAreaId: null,
            territoryId: null,
            warehouseId: null,
            level: null,
            isActive: true,
          },
        },
        territory: {
          id: null,
          territoryAreaId: null,
          code: "",
          name: "",
        },
        territoryArea: {
          id: null,
          code: "",
          name: "",
          type: "",
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          if (this.data.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      var isActive = "1";
      if (this.data.isActive) {
        isActive = "1";
      } else {
        isActive = "0";
      }
      var personalId = [];

      this.personals.forEach(function (val) {
        personalId.push(val.id);
      }, personalId);
      return {
        parent_id: this.parentPersonal.workTeamPersonal.id,
        work_team_id: this.data.workTeamId,
        work_position_id: this.workPosition.ID,
        personal_id: personalId,
        territory_area_id: this.territoryArea.id,
        territory_id: this.territory.id ? this.territory.id : this.territory.ID,
        warehouse_id: this.data.warehouseId,
        level: this.data.level,
        is_active: isActive,
      };
    },
    postData() {
      this.$http
        .post(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Data Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.baseUrl + "/" + this.data.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      console.log("get data : " + this.data.id);
      if (this.data.id) {
        this.$http
          .get(this.baseUrl + "/" + this.data.id)
          .then((resp) => {
            if (resp.code == 200) {
              this.data.id = resp.data.id ? resp.data.id : resp.data.ID;
              this.data.workTeamId = resp.data.work_team_id;
              this.data.warehouseId = resp.data.warehouse_id;
              this.data.level = resp.data.level;
              this.data.parentId = resp.data.parent_id;
              this.workPosition.ID = resp.data.work_position_id;
              this.territoryArea.id = resp.data.territory_area_id;
              this.territory.id = resp.data.territory_id;
              this.personals = [
                {
                  id: null,
                  name: "",
                },
              ];
              this.personals[0].id = resp.data.personal_id;
              console.log("personal id : " + resp.data.personal_id);
              console.log("personals : " + this.personals);
              if (resp.data.is_active == "1") {
                this.data.isActive = true;
              } else {
                this.data.isActive = false;
              }
              this.$vs.loading.close();
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    getDataParent() {
      if (this.data.parentId) {
        this.$http
          .get(this.baseUrl + "/" + this.data.parentId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.parentPersonal.id = resp.data.personal_id;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    setWorkPosition(val) {
      this.workPosition = val;
      this.data.level = val.level;
      if (val.level == 1) {
        this.parentPersonal.id = null;
      }
    },
    setTerritoryArea(val) {
      this.territoryArea = val;
    },
    setTerritory(val) {
      this.territory = val;
    },
    setPersonal(val, a) {
      this.personals[a] = val;
    },
    setParentPersonal(val) {
      if (val.id) {
        this.parentPersonal = val;
        this.territoryArea.id = val.workTeamPersonal.territoryAreaId;
        if (val.workTeamPersonal.territoryId != 0) {
          this.territory.id = val.workTeamPersonal.territoryId;
        }
      }
    },
    handleAddPerson() {
      this.personals.push({
        id: null,
        name: "",
      });
    },
    handleRemovePerson(index) {
      this.personals.splice(index, 1);
    },
  },
  mounted() {
    this.data.workTeamId = this.workTeam.id;
    this.territoryArea.id = this.workTeam.territoryAreaId;
    if (this.id) {
      this.data.id = this.id;
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(val) {
      console.log("form watch : " + val);
      if (val) {
        this.data.id = this.id;
        this.getData();
      } else {
        this.data.id = null;
        Object.assign(this.$data, this.initialState());
        this.data.workTeamId = this.workTeam.id;
      }
    },
    "data.parentId": function () {
      this.getDataParent();
    },
    workTeam(val) {
      this.data.workTeamId = val.id;
      this.territoryArea.id = val.territoryAreaId;
    },
  },
};
</script>
