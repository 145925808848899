<template>
  <vx-card :title="title">
    <div class="flex">
      <div class="w-1/6 bg-grid-color h-12">Name</div>
      <div class="w-1/6 bg-grid-color-secondary h-12">
        :
        <b>{{ this.workTeam.name }}</b>
      </div>
    </div>
    <div class="flex">
      <div class="w-1/6 bg-grid-color h-12">Notes</div>
      <div class="w-1/6 bg-grid-color-secondary h-12">
        :
        <b>{{ this.workTeam.notes }}</b>
      </div>
    </div>
    <div class="flex">
      <div class="w-1/6 bg-grid-color h-12">Sales Area</div>
      <div class="w-1/6 bg-grid-color-secondary h-12">
        :
        <b>{{ this.territoryArea.code }} {{ this.territoryArea.name }}</b>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
      <vs-button
        class="ml-4 mt-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
    </div>
    <div class="vx-row mb-12 ml-6">
      <filter-territory
        @data="setTerritoryId"
        :territoryAreaId="this.workTeam.territoryAreaId"
      ></filter-territory>
    </div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          action == 'edit' || action == 'create' ? detailShow : '',
          detailHide,
        ]"
      >
        <vs-table
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
        >
          <!-- <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">{{this.table.start}} - {{this.table.end}} of {{this.table.total}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{item}}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>-->
          <template slot="thead">
            <vs-th width="10%"></vs-th>
            <vs-th sort-key="parent_name">Personal Parent</vs-th>
            <vs-th sort-key="personal_name">Personal Name</vs-th>
            <vs-th sort-key="work_position_name">Position</vs-th>
            <vs-th sort-key="territory_area_code">Sales Area</vs-th>
            <vs-th sort-key="territory_code">Territory</vs-th>
            <vs-th sort-key="is_active">Status</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  title="Edit"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click.stop="handleEdit(data[indextr].work_team_personal_id)"
                />
                <feather-icon
                  title="Delete"
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                  @click.stop="
                    handleDelete(data[indextr].work_team_personal_id)
                  "
                />
              </vs-td>
              <vs-td>{{ data[indextr].parent_name }}</vs-td>
              <vs-td>{{ data[indextr].personal_name }}</vs-td>
              <vs-td>{{ data[indextr].work_position_name }}</vs-td>
              <vs-td
                >{{ data[indextr].territory_area_code }}
                {{ data[indextr].territory_area_name }}</vs-td
              >
              <vs-td
                >{{ data[indextr].territory_code }}
                {{ data[indextr].territory_name }}</vs-td
              >
              <vs-td>{{
                data[indextr].is_active == "1" ? "Active" : "Inactive"
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- <vs-pagination style="padding-top:5px" :total="table.totalPage" v-model="setPage" /> -->
      </div>
      <transition name="detail-fade">
        <div
          v-if="action == 'edit' || action == 'create'"
          v-bind:class="[
            action == 'edit' || action == 'create' ? detailShow : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <form-component
              :title="title"
              :baseUrl="baseUrl"
              :id="id"
              :workTeam="workTeam"
              @close="handleClose"
            ></form-component>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import FormComponent from "./Form";
import FilterTerritory from "./filter-component/Territory";
export default {
  components: {
    FormComponent,
    FilterTerritory,
  },
  data() {
    return {
      baseUrl: "/api/v1/master/work-team-personal",
      title: "Work Team Personal",
      id: null,
      workTeam: {
        id: null,
        territoryAreaId: null,
        name: "",
        notes: "",
      },
      territoryArea: {
        id: null,
        code: "",
        name: "",
        type: "",
      },
      table: this.tableState(),
      action: "",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      territoryId: null,
    };
  },
  methods: {
    tableState() {
      return {
        data: [],
        length: "",
        page: 1,
        search: "",
        order: "work_team_personal_id",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      if (active) {
        this.table.order = key;
        this.table.sort = active;
      } else {
        this.table.order = "work_team_personal_id";
        this.table.sort = "asc";
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleCreate() {
      this.id = null;
      this.action = "create";
    },
    handleEdit(value) {
      console.log("index :" + value)
      this.id = value;
      this.action = "edit";
    },
    handleDetail(val) {
      this.id = val;
      this.action = "detail";
    },
    handleBack() {
      this.$router.push("/master/work-team");
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.action = "";
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            // length: this.table.length,
            // page: this.table.page,
            // search: this.table.search,
            // order: this.table.order,
            // sort: this.table.sort,
            work_team_id: this.workTeam.id,
            territory_id: this.territoryId,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            // this.table.data = resp.data.records;
            var data = [];
            var getChild = this.getChild;
            var firstLevel = resp.data.records.filter(function (i) {
              return i.level == 1;
            });
            var secondLevel = resp.data.records.filter(function (i) {
              return i.level == 2;
            });
            var thirdLevel = resp.data.records.filter(function (i) {
              return i.level == 3;
            });
            if (firstLevel.length > 0) {
              firstLevel.forEach(function (item) {
                data.push(item);
                var child = getChild(
                  resp.data.records,
                  item.work_team_personal_id
                );
                child.forEach(function (item) {
                  data.push(item);
                }, data);
              });
            } else if (secondLevel.length > 0) {
              secondLevel.forEach(function (item) {
                data.push(item);
                var child = getChild(
                  resp.data.records,
                  item.work_team_personal_id
                );
                child.forEach(function (item) {
                  data.push(item);
                }, data);
              });
            } else if (thirdLevel.length > 0) {
              thirdLevel.forEach(function (item) {
                data.push(item);
                var child = getChild(
                  resp.data.records,
                  item.work_team_personal_id
                );
                child.forEach(function (item) {
                  data.push(item);
                }, data);
              });
            }

            this.table.data = data;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
            this.$vs.loading.close();
          }
        });
    },
    getChild(records, id) {
      var data = [];
      var getChild = this.getChild;
      records.filter(
        function (item) {
          if (item.parent_id == id) {
            data.push(item);
            var child = getChild(records, item.work_team_personal_id);
            child.forEach(function (item) {
              data.push(item);
            }, data);
          }
        },
        data,
        getChild
      );
      return data;
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(value) {
      this.detail = false;
      this.action = "";
      this.id = value;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete(this.baseUrl + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getDataWorkTeam() {
      if (this.workTeam.id) {
        this.$http
          .get("/api/v1/master/work-team/" + this.workTeam.id)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.workTeam.territoryAreaId = resp.data.territory_area_id;
              this.workTeam.name = resp.data.name;
              this.workTeam.notes = resp.data.notes;
            } else {
              this.$router.push("/master/work-team");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$router.push("/master/work-team");
          });
      }
    },
    getDataTerrtoryArea() {
      if (this.workTeam.id) {
        this.$http
          .get("/api/v1/master/territory-area/" + this.workTeam.territoryAreaId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.territoryArea.id = resp.data.id;
              this.territoryArea.name = resp.data.name;
              this.territoryArea.code = resp.data.code;
            } else {
              this.$router.push("/master/work-team");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$router.push("/master/work-team");
          });
      }
    },
    setTerritoryId(val) {
      this.territoryId = val;
    },
  },
  mounted() {
    this.workTeam.id = parseInt(this.$route.params.id);
    this.getDataWorkTeam();
    this.getData();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    territoryId() {
      this.getData();
    },
    "workTeam.territoryAreaId"() {
      this.getDataTerrtoryArea();
    },
  },
};
</script>

 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  /* min-height: 800px; */
  padding: 5px;
}
</style>