var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":_vm.title}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/6 bg-grid-color h-12"},[_vm._v("Name")]),_c('div',{staticClass:"w-1/6 bg-grid-color-secondary h-12"},[_vm._v(" : "),_c('b',[_vm._v(_vm._s(this.workTeam.name))])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/6 bg-grid-color h-12"},[_vm._v("Notes")]),_c('div',{staticClass:"w-1/6 bg-grid-color-secondary h-12"},[_vm._v(" : "),_c('b',[_vm._v(_vm._s(this.workTeam.notes))])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/6 bg-grid-color h-12"},[_vm._v("Sales Area")]),_c('div',{staticClass:"w-1/6 bg-grid-color-secondary h-12"},[_vm._v(" : "),_c('b',[_vm._v(_vm._s(this.territoryArea.code)+" "+_vm._s(this.territoryArea.name))])])]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('vs-button',{staticClass:"ml-4 mt-2",attrs:{"color":"danger","icon-pack":"feather","icon":"icon-arrow-left"},on:{"click":function($event){return _vm.handleBack()}}},[_vm._v("Back")]),_c('vs-button',{staticClass:"ml-4 mt-2",attrs:{"color":"primary","type":"border","icon-pack":"feather","icon":"icon-plus"},on:{"click":function($event){return _vm.handleCreate()}}},[_vm._v("Create")])],1),_c('div',{staticClass:"vx-row mb-12 ml-6"},[_c('filter-territory',{attrs:{"territoryAreaId":this.workTeam.territoryAreaId},on:{"data":_vm.setTerritoryId}})],1),_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.action == 'edit' || _vm.action == 'create' ? _vm.detailShow : '',
        _vm.detailHide,
      ]},[_c('vs-table',{attrs:{"stripe":"","border":"","description":"","sst":true,"data":_vm.table.data,"max-items":_vm.table.length,"total":_vm.table.total},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{staticClass:"whitespace-no-wrap"},[_c('feather-icon',{staticClass:"ml-2",attrs:{"title":"Edit","icon":"EditIcon","svgClasses":"w-5 h-5 hover:text-primary stroke-current"},on:{"click":function($event){$event.stopPropagation();return _vm.handleEdit(data[indextr].work_team_personal_id)}}}),_c('feather-icon',{staticClass:"ml-2",attrs:{"title":"Delete","icon":"TrashIcon","svgClasses":"w-5 h-5 hover:text-danger stroke-current"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(data[indextr].work_team_personal_id)}}})],1),_c('vs-td',[_vm._v(_vm._s(data[indextr].parent_name))]),_c('vs-td',[_vm._v(_vm._s(data[indextr].personal_name))]),_c('vs-td',[_vm._v(_vm._s(data[indextr].work_position_name))]),_c('vs-td',[_vm._v(_vm._s(data[indextr].territory_area_code)+" "+_vm._s(data[indextr].territory_area_name))]),_c('vs-td',[_vm._v(_vm._s(data[indextr].territory_code)+" "+_vm._s(data[indextr].territory_name))]),_c('vs-td',[_vm._v(_vm._s(data[indextr].is_active == "1" ? "Active" : "Inactive"))])],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"width":"10%"}}),_c('vs-th',{attrs:{"sort-key":"parent_name"}},[_vm._v("Personal Parent")]),_c('vs-th',{attrs:{"sort-key":"personal_name"}},[_vm._v("Personal Name")]),_c('vs-th',{attrs:{"sort-key":"work_position_name"}},[_vm._v("Position")]),_c('vs-th',{attrs:{"sort-key":"territory_area_code"}},[_vm._v("Sales Area")]),_c('vs-th',{attrs:{"sort-key":"territory_code"}},[_vm._v("Territory")]),_c('vs-th',{attrs:{"sort-key":"is_active"}},[_vm._v("Status")])],1)],2)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.action == 'edit' || _vm.action == 'create')?_c('div',{class:[
          _vm.action == 'edit' || _vm.action == 'create' ? _vm.detailShow : '',
          _vm.detailHide,
        ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close")])],1),_c('vs-col',{staticClass:"vertical-divider",attrs:{"vs-offset":"0","vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"1"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}})],1),_c('form-component',{attrs:{"title":_vm.title,"baseUrl":_vm.baseUrl,"id":_vm.id,"workTeam":_vm.workTeam},on:{"close":_vm.handleClose}})],1)],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }