<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="name"
      label="name"
      :max-height="125"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number
    }
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/work-position",
        selected: null,
        options: []
      };
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            order: "level",
            sort: "asc"
          }
        })
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.options = resp.data.records;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getDataById() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.selected = resp.data;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    }
  },
  mounted() {
    this.getData();
    if (this.id) {
      this.getDataById();
    }
  },
  watch: {
    id(val) {
      if (val) {
        this.getDataById();
      } else {
        this.selected = null;
      }
    },
    selected(val) {
      if (val) {
        this.$emit("workPosition", val);
      } else {
        this.$emit("workPosition", {
          id: null,
          name: "",
          level: null
        });
      }
    }
  }
};
</script>