<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="id"
      label="name"
      :max-height="125"
      @search-change="handleSearch"
      :showNoOptions="false"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} {{ props.option.name }}</span
          >
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} {{ props.option.name }}</span
          >
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
    },
    workTeamId: {
      type: Number,
    },
    workTeamPersonalId: {
      type: Number,
    },
    territoryAreaId: {
      type: Number,
    },
    territoryId: {
      type: Number,
    },
    level: {
      type: Number,
    },
    index: {
      type: Number,
    },
    personal: {
      type: Array,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/work-team-personal/user",
        selected: null,
        options: [],
        search: "",
      };
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            search: this.search,
            order: "name",
            sort: "asc",
            work_team_id: this.workTeamId,
            level: this.level,
            territory_id: this.territoryId,
            territory_area_id: this.territoryAreaId,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            var options = [];
            var personal = this.personal;
            resp.data.records.filter(
              function (option) {
                var push = true;
                personal.filter(
                  function (itemPersonal) {
                    if (option.id == itemPersonal.id) {
                      push = false;
                    }
                  },
                  option,
                  push
                );
                if (push) {
                  options.push(option);
                }
              },
              options,
              personal
            );
            this.options = options;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleSearch(query) {
      if (query != "") {
        this.search = query;
        this.getData();
      } else {
        this.options = [];
      }
    },
    setOption(data) {
      return new Promise((resolve) => {
        this.options = [];
        this.options.push(data);
        resolve(true);
      });
    },
    getDataById() {
      this.$vs.loading();
      console.log("personal get data by id : " + this.id);
      this.$http
        .get(this.baseUrl + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            let a = this.setOption(resp.data);
            a.then(() => {
              this.selected = resp.data;
              console.log(
                "selected person : " + resp.data.id + " - " + resp.data.name
              );
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.id) {
      this.getDataById();
    }
  },
  watch: {
    selected(val) {
      if (val) {
        this.$emit(
          "personal",
          {
            id: val.id,
            name: val.name,
            workTeamPersonal: {
              id: val.work_team_personal.id,
              parentId: val.work_team_personal.parent_id,
              workTeamId: val.work_team_personal.work_team_id,
              workPositionId: val.work_team_personal.work_position_id,
              personalId: val.work_team_personal.personal_id,
              territoryAreaId: val.work_team_personal.territory_area_id,
              territoryId: val.work_team_personal.territory_id,
              warehouseId: val.work_team_personal.warehouse_id,
              level: val.work_team_personal.level,
              isActive: val.work_team_personal.is_active,
            },
          },
          this.index
        );
      } else {
        this.options = [];
        this.$emit(
          "personal",
          {
            id: null,
            name: "",
            workTeamPersonal: {
              parentId: null,
              workTeamId: null,
              workPositionId: null,
              personalId: null,
              territoryAreaId: null,
              territoryId: null,
              warehouseId: null,
              level: null,
              isActive: true,
            },
          },
          this.index
        );
      }
    },
    level(val) {
      if (val) {
        this.getData();
      } else {
        this.options = [];
      }
    },
    territoryId() {
      this.selected = null;
    },
    territoryAreaId() {
      this.selected = null;
    },
    id(val) {
      if (val) {
        this.getDataById();
      }
    },
  },
  computed: {},
};
</script>