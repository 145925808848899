<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="id"
      label="name"
      :max-height="125"
      @search-change="handleSearch"
      :showNoOptions="true"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number
    },
    workTeamId: {
      type: Number
    },
    workTeamPersonalId: {
      type: Number
    },
    level: {
      type: Number
    },
    index: {
      type: Number
    }
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/work-team-personal/user",
        selected: null,
        options: [],
        search: ""
      };
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            search: this.search,
            order: "name",
            sort: "asc",
            work_team_id: this.workTeamId,
            level: this.level
          }
        })
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.options = resp.data.records;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleSearch(query) {
      if (query != "") {
        this.search = query;
        this.getData();
      } else {
        this.options = [];
      }
    },
    getDataById() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/" + this.id, {
          params: {
            work_team_id: this.workTeamId
          }
        })
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.selected = resp.data;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    }
  },
  mounted() {
    this.getData();
    if (this.id) {
      this.getDataById();
    }
  },
  watch: {
    selected(val) {
      if (val) {
        this.$emit(
          "personal",
          {
            id: val.id,
            name: val.name,
            workTeamPersonal: {
              id: val.work_team_personal.id,
              parentId: val.work_team_personal.parent_id,
              workTeamId: val.work_team_personal.work_team_id,
              workPositionId: val.work_team_personal.work_position_id,
              personalId: val.work_team_personal.personal_id,
              territoryAreaId: val.work_team_personal.territory_area_id,
              territoryId: val.work_team_personal.territory_id,
              warehouseId: val.work_team_personal.warehouse_id,
              level: val.work_team_personal.level,
              isActive: val.work_team_personal.is_active
            }
          },
          this.index
        );
      } else {
        this.$emit(
          "personal",
          {
            id: null,
            name: "",
            workTeamPersonal: {
              parentId: null,
              workTeamId: null,
              workPositionId: null,
              personalId: null,
              territoryAreaId: null,
              territoryId: null,
              warehouseId: null,
              level: null,
              isActive: true
            }
          },
          this.index
        );
      }
    },
    level(val) {
      this.selected = null;
      if (val) {
        this.getData();
      } else {
        this.options = [];
      }
    },
    id(val) {
      this.getData();
      if (val) {
        this.getDataById();
      }
    },
    workTeamId() {
      this.getData();
    }
  }
};
</script>